/* tslint:disable: no-duplicate-imports */
import { Module } from '@msdyn365-commerce-modules/utilities';
import * as React from 'react';
import { IExperlogixWebConfiguratorViewProps } from './experlogix-web-configurator';

/**
 *
 * ExperlogixWebConfiguratorView component
 * @extends {React.PureComponent<IExperlogixWebConfiguratorViewProps>}
 */
export class ExperlogixWebConfiguratorView extends React.PureComponent<IExperlogixWebConfiguratorViewProps> {
    public render(): JSX.Element | null {
        const { Alert, cookieConsentAccepted, cookieConsentRequired, Container, Modal, ViewPort } = this.props;

        return (
            <Module {...Container}>
                {Alert}
                {!cookieConsentRequired || cookieConsentAccepted === true ? ViewPort : null}
                {Modal}
            </Module>
        );
    }
}
export default ExperlogixWebConfiguratorView;
