import { IGetExperlogixStateData, IProductComplexity } from '.';
import { assembleUrlParams, getExperlogixRootUrl, getExperlogixUrl } from './urlUtilities';
import * as MsDyn365 from '@msdyn365-commerce/core';
import { getActionContext, GetExperlogixStateInput, getProductStateAsync } from '../actions/get-experlogix-state.action';
import { ICoreContext } from '@msdyn365-commerce/core';

async function getProductComplexityAsync(productItemId: string, experlogixSiteUrl: string): Promise<IProductComplexity | null> {
    const response = await MsDyn365.sendRequest<IProductComplexity>(
        `${getExperlogixRootUrl(experlogixSiteUrl)}/site/handlers/eCommerceProductComplexity.ashx`,
        'post',
        assembleUrlParams({ key: 'ItemId', value: productItemId })
    );
    if (response.status === 200) {
        return response.data || null;
    }
    return null;
}

export async function isComplexAsync(productItemId: string, experlogixSiteUrl: string, maxCategories: number): Promise<boolean> {
    const maxPopups = 0; //Product Owner group does not want this setting to be in the site builder

    const productComplexity = await getProductComplexityAsync(productItemId, experlogixSiteUrl);

    //The default values for these variables are maxPopups = 0, maxCategories = 6 and were defined by the Product Owner group however 'maxPopups' can be changed from the site builder.
    //Here we validate if the product is complex or not depending on the rules
    if (productComplexity !== null && (productComplexity?.categories > maxCategories || productComplexity?.popups > maxPopups)) {
        return true;
    }
    return false;
}

export async function getExperlogixStateAsync(context: ICoreContext, productId: number): Promise<IGetExperlogixStateData> {
    const actionContext = getActionContext(context);
    const experlogixSiteUrl = getExperlogixUrl(context);

    const input = new GetExperlogixStateInput(experlogixSiteUrl, productId);
    const result = await getProductStateAsync(input, actionContext);
    return result;
}
