import classnames from 'classnames';
import * as React from 'react';
import IframeResizer, { ResizerOptions } from 'iframe-resizer-react';

export interface IExperlogixIframeHostProps {
    iframeRef: React.Ref<HTMLIFrameElement>;
    experlogixUrl: string;
    ariaLabel?: string;
    heightCalculationMethod?: ResizerOptions['heightCalculationMethod'];
    minWidth?: string | undefined;
    minHeight?: string | undefined;
    width?: string | undefined;
    autoResize?: ResizerOptions['autoResize'];
    resizeFrom?: ResizerOptions['resizeFrom'];
    scrolling?: ResizerOptions['scrolling'];
    log?: boolean | undefined;
}

export default class ExperlogixIframeHost extends React.PureComponent<IExperlogixIframeHostProps> {
    public render(): JSX.Element | null {
        const {
            iframeRef,
            experlogixUrl,
            ariaLabel,
            heightCalculationMethod,
            minWidth,
            minHeight,
            width,
            autoResize,
            resizeFrom,
            scrolling,
            log
        } = this.props;
        return (
            // tslint:disable-next-line: react-iframe-missing-sandbox
            <IframeResizer
                forwardRef={iframeRef}
                heightCalculationMethod={heightCalculationMethod}
                className={classnames('exp-iframe__content')}
                src={experlogixUrl}
                aria-label={ariaLabel}
                autoResize={autoResize}
                resizeFrom={resizeFrom}
                scrolling={scrolling}
                log={log}
                sizeHeight={true}
                style={{ width: width, minWidth: minWidth, minHeight: minHeight, border: 'none' }}
            />
        );
    }
}
